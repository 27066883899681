import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { checkboxMapper } from '../../../utility/helpers';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CustomButton from '../../../components/Button/CustomButton';
import Textbox from '../../../components/Textbox/Textbox';
import Loading from '../../../components/Loading/Loading';
import SomethingWentWrong from '../../../components/Error/SomethingWentWrong';
import { Link } from 'react-router-dom';

import CheckboxForm from '../components/CheckboxForm';
import { getProjectFormData } from '../../../api/Projects';
import {
  clearProject,
  setProjectData,
} from '../../../store/actions/projectActions';
import {
  selectProjectData,
  selectProjectErrors,
  selectIsLoading,
} from '../../../store/selectors/projectSelectors';

import { client } from '../../../api/config/api';

import classes from './projectForm.module.scss';

/*
Temporary constants
*/

const impact = [
  { id: 1, value: 1, active: false },
  { id: 2, value: 2, active: false },
  { id: 3, value: 3, active: false },
  { id: 4, value: 4, active: false },
];

const commitment = [
  { id: 1, value: 1, active: false },
  { id: 2, value: 2, active: false },
  { id: 3, value: 3, active: false },
  { id: 4, value: 4, active: false },
];

const ProjectForm = ({
  onSubmit,
  parentTrackId,
  clearProject,
  setProjectData,
  projectData,
  isLoading,
  projectDataErrors,
  edit
}) => {
  const { pathname } = useLocation();
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [whoCanIInvite, setWhoCanIInvite] = useState('');
  const [businessImpact, setBusinessImpact] = useState(impact);
  const [personalCommitment, setPersonalCommitment] = useState(commitment);
  const [aims, setAims] = useState([]);
  const [selectedAims, setSeletectedAims] = useState([]);
  const [improvements, setImprovements] = useState([]);
  const [selectedImprovements, setSelectedImprovements] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const btnText = edit ? 'Save' : 'Create Launchpad';

  const selectAims = (event) => {
    const clonedAims = JSON.parse(JSON.stringify(aims));
    const index = aims.findIndex((i) => i.name === event?.target?.name);
    clonedAims[index].checked = event?.target?.checked;
    setAims(clonedAims);
    setSeletectedAims(
      clonedAims
        .map((item) => {
          if (item.checked) {
            return item.name;
          }
        })
        .filter(Boolean)
    );
  };

  const selectImprovements = (event) => {
    const clonedImprovements = JSON.parse(JSON.stringify(improvements));
    const index = improvements.findIndex((i) => i.name === event?.target?.name);
    clonedImprovements[index].checked = event?.target?.checked;
    setImprovements(clonedImprovements);
    setSelectedImprovements(
      clonedImprovements
        .map((item) => {
          if (item.checked) {
            return item.name;
          }
        })
        .filter(Boolean)
    );
  };

  const projectHandler = async () => {
    let hasError = false;
    if (!projectName) {
      setNameError(true);
      hasError = true;
    }
    if (!projectDescription) {
      setDescriptionError(true);
      hasError = true;
    }
    if (hasError) {
      return;
    }

    const impact = get(
      businessImpact?.filter((i) => i?.active)[0],
      'value',
      null
    );
    const targetStakeholderImpact = get(
      personalCommitment?.filter((i) => i?.active)[0],
      'value',
      null
    );

    onSubmit({
      parentTrackId,
      projectName,
      projectDescription,
      whoCanIInvite,
    });
  };

  useEffect(() => {
    if(edit){
      setProjectDescription(projectData?.description);
      setProjectName(projectData?.name);
      setWhoCanIInvite(projectData?.whoCanIInvite);
    }
    
    client()
      .query({
        query: getProjectFormData,
      })
      .then((result) => {
        const { improvementAreas, outputTypes } = get(
          result,
          'data.projectFormData',
          null
        );
        // outputTypes && setAims(checkboxMapper(outputTypes));
        // improvementAreas && setImprovements(checkboxMapper(improvementAreas));
        // if (outputTypes && improvementAreas) {
        //   setLoaded(true);
        // }
        setLoaded(true);
      })
      .catch((e) => {
        setError(true);
        console.error(e);
      });
  }, []);

  if (!loaded) {
    return (
      <div style={{ marginTop: 50 }}>
        <Grid container>
          <Grid item xs={12}>
            <Loading size="5rem" />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  /*
More important than expertise, who can I invite as teammates who will care about this project,
 especially who will have deep empathy and compassion for the lives who will benefit from our outcomes and impact?
  */
  return (
    <div>
      <form className={classes.projectForm}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.detailsContent}>
              Every Launchpad is a <b>Winnovation (winning innovation)</b> whose time has come. Here across 12 Hurdles and 3 Zones, we create big wins for customers, or for company financials, or workforce performance - and we ensure that these wins are sustainable at scale. 
              <br/><br/>
              As a result, every Launchpad creates <b>next level breakthroughs</b>, both for my career and my team's success.
              <br/><br/>
              I describe the market challenge, problem or opportunity I'd like to address, or the product or solution I want to build. If I don't have something specific in mind, I can describe problems I typically like to solve.
              <div className={classes.linkContainer}>
                <Link to={`/example`} className={classes.link}>
                  <div>Example</div>
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.projectFormWrapper}>
              <Textbox
                fullWidth
                label="Description"
                multiline
                rows={4}
                variant="outlined"
                value={projectDescription}
                onChange={(event) => {
                  if (event?.target?.value) {
                    setDescriptionError(false);
                  }
                  setProjectDescription(event?.target?.value)
                }}
                required
                error={descriptionError}
                helperText={descriptionError && `This field is required!`}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.projectFormWrapper}>
              <Textbox
                fullWidth
                label="Project Name"
                variant="outlined"
                value={projectName}
                onChange={(event) => {
                  if (event?.target?.value) {
                    setNameError(false);
                  }
                  setProjectName(event?.target?.value);
                }}
                required
                error={nameError}
                helperText={nameError && `This field is required!`}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.detailsContent}>
              More important than expertise, who can I invite as teammates who will care about this project, especially who will have deep empathy and compassion for the lives who will benefit from our outcomes and impact?
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.projectFormWrapper}>
              <Textbox
                fullWidth
                label=""
                placeholder="2-6 people I want to team up with for this Launchpad"
                multiline
                rows={4}
                variant="outlined"
                value={whoCanIInvite}
                onChange={(event) => {
                  setWhoCanIInvite(event?.target?.value)
                }}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <div
              style={{ borderTop: '1px dashed #BCBCBC', margin: '10px 20px' }}
            />
            <div
              className={classes.projectFormWrapper}
              style={{ textAlign: 'left' }}
            >
              <h4 className={classes.heads}>
                This 100H project aims to launch a/an
              </h4>
              <p className={classes.subHeads}>select the best fit up to 3</p>
            </div>
            <CheckboxForm
              gridSize={6}
              gridData={aims}
              selectItem={selectAims}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className={classes.projectFormWrapper}
              style={{ textAlign: 'left' }}
            >
              <h4 className={classes.heads}>
                in order to improve my company's
              </h4>
              <p className={classes.subHeads}>select the best fit up to 3</p>
            </div>
            <CheckboxForm
              gridSize={6}
              gridData={improvements}
              selectItem={selectImprovements}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{ borderTop: '1px dashed #BCBCBC', margin: '10px 20px' }}
            />
            <div
              className={classes.projectFormWrapper}
              style={{ textAlign: 'left' }}
            >
              <h4 className={classes.heads}>Business Impact</h4>
              <ButtonGroup
                fullWidth
                variant="contained"
                style={{
                  marginTop: 16,
                }}
              >
                {businessImpact.map((i, index) => (
                  <CustomButton
                    key={'hurdleZeroImpact' + i.id}
                    onClick={() => {
                      const item = JSON.parse(JSON.stringify(businessImpact));
                      item && (item[index].active = !item[index].active);
                      setBusinessImpact(item);
                    }}
                    style={{
                      background: i.active ? `#276EF1` : `#E3EAF7`,
                      border: 'none',
                      color: '#FFFFFF',
                    }}
                  >
                    {i.value}
                  </CustomButton>
                ))}
              </ButtonGroup>
              <Grid container>
                <Grid item xs={6}>
                  <span className={classes.subFooters}>Not Really</span>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <span className={classes.subFooters}>A Lot</span>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              className={classes.projectFormWrapper}
              style={{ textAlign: 'left' }}
            >
              <h4 className={classes.heads}>Personal Commitment</h4>
              <ButtonGroup
                fullWidth
                variant="contained"
                style={{
                  marginTop: 16,
                }}
              >
                {personalCommitment.map((i, index) => (
                  <CustomButton
                    key={'hurdleZeroImpact' + i.id}
                    onClick={() => {
                      const item = JSON.parse(
                        JSON.stringify(personalCommitment)
                      );
                      item && (item[index].active = !item[index].active);
                      setPersonalCommitment(item);
                    }}
                    style={{
                      background: i.active ? `#276EF1` : `#E3EAF7`,
                      border: 'none',
                      color: '#FFFFFF',
                    }}
                  >
                    {i.value}
                  </CustomButton>
                ))}
              </ButtonGroup>
              <Grid container>
                <Grid item xs={6}>
                  <span className={classes.subFooters}>Not Really</span>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <span className={classes.subFooters}>A Lot</span>
                </Grid>
              </Grid>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <div
              className={classes.projectFormWrapper}
              style={{ marginTop: 32 }}
            >
              <CustomButton
                color="primary"
                fullWidth
                size="large"
                style={{
                  background: `#276EF1`,
                  color: '#FFFFFF',
                  textTransform: 'none'
                }}
                onClick={projectHandler}
                disabled={isLoading || (nameError && descriptionError)}
              >
                {btnText}
              </CustomButton>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 20, marginTop: 20 }}></Grid>
        </Grid>
      </form>
    </div>
  );
};

ProjectForm.propTypes = {
  onSubmit: PropTypes.func,
  parentTrackId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading('projectData'),
  projectDataErrors: selectProjectErrors,
  projectData: selectProjectData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearProject,
      setProjectData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
