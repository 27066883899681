import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { bindActionCreators } from 'redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { setWorkData, setOpenSnackbar } from '../../../../store/actions/projectActions';
import { selectSnackbar } from '../../../../store/selectors/projectSelectors'
import { createStructuredSelector } from 'reselect';
import Header from '../../../Header';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { BackLink } from '../../../../components/Links';
import * as HurdlesQueries from '../../../../api/Hurdles';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { emphasize, withStyles } from "@material-ui/core/styles";
import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import classes from './work.module.scss';

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(104, 154, 245, 0.6);',
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const ToolTipTheme = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "gray"
    }
})(Tooltip);

const WorkComponent = ({ setWorkData, routeId, snackbar, setOpenSnackbar, hurdleName, subStopInfo }) => {
    const toolTipText = `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.`
    const ages = [];
    for (let x = 0; x <= 100; x++) {
        ages.push(x);
    }
    const history = useHistory();
    const [initialAnswer, setInitialAnswer] = useState('');
    const [radioValue, setRadioValue] = useState('');
    const [age, setAge] = useState(0);
    const [answer1, setAnswer1] = useState('');
    const [answer2, setAnswer2] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [answer4, setAnswer4] = useState('');
    const [openSB, setOpenSB] = useState(snackbar.switch);

    const [questions, setQuestions] = useState([
        { id: '', answer: '', notes: '' },
        { id: '', answer: '', notes: '' },
        { id: '', answer: '', notes: '' },
        { id: '', answer: '', notes: '' },
        { id: '', answer: '', notes: '' },
        { id: '', answer: '', notes: '' },
        { id: '', answer: '', notes: '' },
    ]);


    const [substops, setSubstops] = useState([
        {
            id: null,
            status: null,
            questions: []
        }
    ]);


    const handleFormSubmit = (e) => {
        e.preventDefault();
        setQuestions([
            { initialAnswer },
            { radioValue },
            { age },
            { answer1 },
            { answer2 },
            { answer3 },
            { answer4 },
        ])
        setSubstops([
            {
                id: 1,
                status: 'Done',
                questions: [
                    { ...initialAnswer },
                    { ...radioValue },
                    { ...age },
                    { ...answer1 },
                    { ...answer2 },
                    { ...answer3 },
                    { ...answer4 },
                ]
            }
        ])
        setOpenSB(true)
    }

    const handleAnswerChange = (event) => {
        if (event.target.name === 'question1') {
            let ans1 = event.target.value;
            setAnswer1({ ans1: ans1 });
            console.log(ans1);
        }
        if (event.target.name === 'question2') {
            let ans2 = event.target.value;
            setAnswer2({ ans2: ans2 });
            console.log(ans2)
        }
        if (event.target.name === 'question3') {
            let ans3 = event.target.value;
            setAnswer3({ ans3: ans3 });
            console.log(ans3)
        }
        if (event.target.name === 'question4') {
            let ans4 = event.target.value;
            setAnswer4({ ans4: ans4 });
            console.log(ans4)
        }
    }

    const clearForm = () => {
        setInitialAnswer('');
        setRadioValue('');
        setAge(0);
        setAnswer1('');
        setAnswer2('');
        setAnswer3('');
        setAnswer4('');
    }

    const answerValue = (item) => {
        if (item.id === 1) { return setAnswer1(item.value) }
        if (item.id === 2) { return setAnswer2(item.value) }
        if (item.id === 3) { return setAnswer3(item.value) }
        if (item.id === 4) { return setAnswer4(item.value) }
    }

    useEffect(() => {
        setWorkData(substops)

        substops.map((item) => {
            if (item.id === 1) {
                console.log(item)
                if (item.status === 'Done') {
                    return (
                        setOpenSnackbar({
                            snackbar: {
                                subStop: 'solo_workout',
                                switch: openSB
                            }
                        }),
                        history.push(`/launchpad/${routeId.launchpadId}/zones/${routeId.zoneId}/hurdles/${routeId.hurdleId}`),
                        setTimeout(() => (
                            setOpenSnackbar({
                                snackbar: {
                                    subStop: '',
                                    switch: false
                                }
                            })
                        ), 3000)
                    )
                }
            }
        })


    }, [questions, substops]);


    return (
        <div className={classes.workPageWrapper}>

            <div className={classes.workPage__header}>
                <div className={classes.header__links}>
                    <Header>
                        <BackLink to={`/launchpad/${routeId.launchpadId}/zones/${routeId.zoneId}/hurdles/${routeId.hurdleId}/substop/prework`}>Back</BackLink>
                    </Header>
                </div>
                <div className={classes.header__breadcrumbs}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
                        <StyledBreadcrumb component={Link} to={`/launchpad/${routeId.launchpadId}/zones/${routeId.zoneId}/hurdles/${routeId.hurdleId}`} label={`Hurdle ${routeId.hurdleId} | ${hurdleName}`} className={classes.breadcrumbs__firstDegree} />
                        <StyledBreadcrumb component={Link} to={`/launchpad/${routeId.launchpadId}/zones/${routeId.zoneId}/hurdles/${routeId.hurdleId}/substop/prework`} label={`${get(subStopInfo, 'name')}`} className={classes.breadcrumbs__secondDegree} />
                        <StyledBreadcrumb component={Link} to={`/launchpad/${routeId.launchpadId}/zones/${routeId.zoneId}/hurdles/${routeId.hurdleId}/substop/prework/work`} label="Work" className={classes.breadcrumbs__thirdDegree} />
                    </Breadcrumbs>
                </div>
                <div className={classes.header__title}>
                    <h1>Work</h1>
                    <p>{`${get(subStopInfo, 'caption')}`}</p>
                </div>
            </div>

            <div className={classes.workPage__content} >
                <form onSubmit={handleFormSubmit}>
                    <div className={classes.initialQuestionnaire}>

                        <div className={classes.initialQuestionnaire__section1}>
                            <h3>Why would our
                                <ToolTipTheme title={toolTipText} arrow>
                                    <span> {`${hurdleName}`} </span>
                                </ToolTipTheme>
                            benefit from this?</h3>

                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={5}
                                value={initialAnswer}
                                placeholder="as represented by a specific person (ideally someone I know well).
                "               variant="outlined"
                                fullWidth={true}
                                required={true}
                                onChange={(e) => setInitialAnswer(e.target.value)}
                                className={classes.initialAnswerTextField}
                            />
                        </div>

                        <div className={classes.initialQuestionnaire__section2}>
                            <div className={classes.gender}>
                                <h3>Gender</h3>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="gender" name="gender1" value={radioValue} onChange={(e) => setRadioValue(e.target.value)} className={classes.radioButtons}>
                                        <FormControlLabel value="female" control={<Radio color='primary' required={true} />} label="Female" />
                                        <FormControlLabel value="male" control={<Radio color='primary' required={true} />} label="Male" />
                                        <FormControlLabel value="other" control={<Radio color='primary' required={true} />} label="Other" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className={classes.age}>
                                <h3>Age</h3>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth={true}
                                    required
                                    className={classes.ageTextField}
                                >
                                    {ages.map((age) => (
                                        <option key={age}>
                                            {age}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                    </div>

                    <div className={classes.questionnaire}>
                        <div className={classes.questionnaire__header}>
                            <h3>Fill in the Blanks</h3>
                            <p>I put myself in their shoes.</p>
                        </div>

                        <div className={classes.questions}>
                            <List component='div' disablePadding className={classes.questions__list}>
                                {[
                                    { id: 1, title: 'Primary Motivation', placeholder: 'Primary Motivations', value: answer1 },
                                    { id: 2, title: 'Keep me busy', placeholder: 'keeps me busy', value: answer2 },
                                    { id: 3, title: 'Makes me happy', placeholder: 'makes me happy', value: answer3 },
                                    { id: 4, title: 'Most of all, I need', placeholder: 'most of all, I need', value: answer4 }
                                ].map((item) => (
                                    <ListItem className={classes.nested} key={item.id}>
                                        <ListItemText primary={item.title} className={classes.title} />
                                        <TextField
                                            id="outlined-multiline-static"
                                            className={classes.content}
                                            name={`question${item.id}`}
                                            onInput={(item) => answerValue(item)}
                                            multiline
                                            rows={4}
                                            placeholder={item.placeholder}
                                            variant="outlined"
                                            fullWidth={true}
                                            onChange={handleAnswerChange}
                                            required={true}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </div>
                    <div className={classes.submitButton__section}>
                        <Button type='submit' variant="contained" color='primary' className={classes.submitButton}>Submit</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

WorkComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    handleAnswerChange: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    snackbar: selectSnackbar
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setWorkData,
            setOpenSnackbar
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(WorkComponent)
