import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Launch from '@material-ui/icons/Launch';
import classes from './textfieldTypesComponent.module.scss';


const TextFieldTypesComponent = () => {

    return (
        <div className={classes.textfieldTypesPage}>
            <div className={classes.textfieldTypesPage__title}>
                <h1>Text Fields - Types</h1>
                <p className={classes.styleInstruction}>For usage, kindly refer to the Material UI <a href="https://material-ui.com/components/text-fields/" target="_blank">Text Fields <Launch /></a></p>
            </div>
            <div className={classes.textfieldTypesPage__container}>

                <div className={classes.textfieldTypesPage__section1}>
                    <div className={classes.section1__textField1}>
                        <h2>Placeholder</h2>
                        <TextField id="outlined" label="" placeholder='Label' variant="outlined" />
                    </div>
                    <div className={classes.section1__textField2}>
                        <h2>Labelled</h2>
                        <TextField id="outlined" label="Label name" defaultValue="Text" variant="outlined" />
                    </div>
                    <div className={classes.section1__textField3}>
                        <h2>Right Icon</h2>
                        <TextField
                            label='Label name'
                            defaultValue='Text'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                    </div>
                </div>

                <div className={classes.textfieldTypesPage__section2}>
                    <div className={classes.section2__textField1}>
                        <h2>Multiline</h2>
                        <TextField id="outlined-multiline-static" label="Multiline" multiline rows={5} defaultValue="Outlined text fields have less visual 
emphasis than filled text fields. When they appear in places like " variant="outlined" />
                    </div>
                    <div className={classes.section2__textField2}>
                        <h2>Textbox</h2>
                        <TextField id="outlined-multiline-static" multiline rows={5} defaultValue="Outlined text fields have less visual 
emphasis than filled text fields. When they appear in places like " variant="outlined" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextFieldTypesComponent;
