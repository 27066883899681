import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, SwipeableDrawer, Modal, Backdrop, Fade } from '@material-ui/core';
import Layout, { Header } from '../../components/Layout';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '../../assets/images/logout.png';

import { selectUserProfileRaw, selectParentTrack, selectMenu } from '../../store/selectors';
import { menuSetData, resetMenu } from '../../store/actions/menuActions';

import classes from './menu.module.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '640px',
    width: window.screen.width,
    height: '100v',
    margin: 'auto auto'
  },
  paper: {
    backgroundColor: '#FFF',
    boxShadow: theme.shadows[5],
    height: '100vh',
    maxWidth: '640px',
    width: window.screen.width,
    overflow: 'auto',
  },
}));


const Menu = ({ menu, menuSetData, resetMenu, user, parent }) => {
  const styles = useStyles();
  const history = useHistory();
  const avatarImg = get(user, 'avatarUrl', {})
  // TODO: transfer to fetch call
  const items = [
    { id: uuidv4(), path: 'https://restart.ph/today/', name: 'Today @ Ni2' },
    { id: uuidv4(), path: '/', name: 'Mission Control' },
    { id: uuidv4(), path: '/launchpad', name: 'Launchpad' },
    { id: uuidv4(), path: '/daily-flights', name: 'Daily Flights' },
    { id: uuidv4(), path: '/sandbox', name: 'Ni2 Sandbox' },
    { id: uuidv4(), path: '/future-of-work-now', name: '#FutureOfWork Now' },
    { id: uuidv4(), path: '/superpower-6', name: 'Superpower-6' },
    { id: uuidv4(), path: '/gps', name: 'GPS Navigation' },
    { id: uuidv4(), path: 'https://www.facebook.com/groups/ignitehouseofinnovation', name: 'Unlimited Fuel' },
  ]

  const handleClose = () => {
    menuSetData(false);
  };

  let navs = null;
  if (items.length) {
    navs = items.map(i => {
      if (!i.path) {
        return (
          <div key={i.id}></div>
        )
      }
      const isActive = parent.find(p => (p.path === i.path && p.isActive));
      return (
        <div key={i.id}
          className={classes.listLabel}
          style={{
            backgroundColor: isActive ? '#689AF5' : '#FFF',
            color: isActive ? '#FFF' : '#595959',
          }}
          onClick={e => {
            e.stopPropagation();
            if (i.name === 'Unlimited Fuel' || i.name === 'Today @ Ni2') {
              window.open(i.path, '_blank');
            } else {
              handleClose();
              history.push(i.path);
            }
          }}
        >
          <div>{i.name}</div>
        </div>
      );
    })
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={menu}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Fade in={menu}>
          <div className={styles.paper}>
            <Header
              leftFn={() => {
                menuSetData(!menu);
                history.push('/');
              }}
              rightFn={() => menuSetData(!menu)}
              icon={(<CloseIcon />)}
              style={{
                borderBottom: 'none',
                backgroundColor: '#FFF'
              }}
            />
            <div>
              <div
                className={classes.menuUser}
                onClick={e => {
                  e.stopPropagation();
                  handleClose();
                  history.push('/profile')
                }}
              >
                {
                  avatarImg ?
                    <Avatar style={{ height: 56, width: 56, marginLeft: 16 }} src={avatarImg} /> :
                    <Avatar style={{ height: 56, width: 56, marginLeft: 16 }} />
                }
                <div className={classes.labelName}>
                  {`${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`}
                </div>
              </div>
              <div className={classes.navWrapper}>
                {navs}
                <div>
                  <div
                    style={{
                      margin: '0 16px 80px',
                      textAlign: 'center'
                    }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<img src={ExitToAppIcon} />}
                      size="large"
                      style={{
                        background: '#276EF1',
                        color: '#fff',
                        borderRadius: '8px',
                        marginTop: '32px',
                        boxShadow: 'none',
                        height: '56px',
                        textTransform: 'none'
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        history.push('/logout');
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}


const mapStateToProps = createStructuredSelector({
  menu: selectMenu,
  user: selectUserProfileRaw,
  parent: selectParentTrack,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      menuSetData,
      resetMenu,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
